/* global self */
/* global VERBOSE */
'use strict'

import '../helpers/polyfills'
import { get, noop } from 'lodash-es'
import parseUrl from 'url-parse'
import wixDataProxyCreator from '../wix-data/wixDataProxy'
import { traceCreators, logger as loggerCreator } from '../logger'
import { isEnvEditor } from '../helpers/viewMode'
import DataFetcher from '../inverted-dependencies/DataFetcher'
import LegacyDataFetcher from '../inverted-dependencies/LegacyDataFetcher'
import DataCache from '../inverted-dependencies/DataCache'
import Features from '../inverted-dependencies/Features'
import TempFesClientImpl from '../data/TempFesClientImpl'
import WixDataProvider from '../data/WixDataProvider'
import DataBinding from './DataBinding'
import schemaAPICreator from '../schemas/schemaAPI'
import { createDataSchemasClientForBrowser } from '@wix/wix-data-schemas-client'
import { viewerAutomationsClientCreator } from '@wix/wix-code-automations-client'

export default class App {
  #dataBinding
  #appLogger
  #schemaAPI
  #wixDataProxy
  #wixDataSchemasProxy
  #wixDataCreator
  #getElementorySupport
  #errorReporter
  #originalVerboseReporter
  #shouldVerbose
  #automationsClientCreator

  constructor({
    //TODO: all this crap is in constructor, because it can be passed from IT tests. AAAAAAA!!!!!
    elementorySupport = global.elementorySupport,
    wixDataCreator = ({ baseUrl, envIsEditor, platformizedWixData }) => ({
      wixData: platformizedWixData || self.require('wix-data').default,
      wixDataSchemas: createWixDataSchemasClient(
        global.elementorySupport,
        baseUrl,
        envIsEditor,
      ),
    }),
    errorReporter = (message, error) => console.error(message, error), // eslint-disable-line no-console
    verboseReporter = (...args) => console.verbose(...args), // eslint-disable-line no-console
    shouldVerbose = Boolean(VERBOSE),
    appLogger = loggerCreator({
      global: self,
      appName: 'dbsm-viewer-app',
    }),
    automationsClientCreator = elementorySupport =>
      viewerAutomationsClientCreator({
        requestFunction: global.fetch,
        elementorySupport,
      }),
    getElementorySupport = () => global.elementorySupport,
  } = {}) {
    this.#appLogger = appLogger
    this.#wixDataCreator = wixDataCreator
    this.#getElementorySupport = getElementorySupport
    this.#errorReporter = errorReporter
    this.#originalVerboseReporter = verboseReporter
    this.#shouldVerbose = shouldVerbose
    this.#automationsClientCreator = automationsClientCreator

    return {
      initAppForPage: this.initAppForPage,
      createControllers: this.createControllers,
    }
  }

  initAppForPage = (
    { routerReturnedData },
    _,
    wixSdk,
    {
      bi = {},
      reportTrace = noop,
      monitoring: { createMonitor: createRavenClient },
      fedOpsLoggerFactory,
      biLoggerFactory,
      essentials,
    } = {},
  ) => {
    try {
      const viewMode = get(wixSdk, ['window', 'viewMode'])
      const features = new Features({
        experiments: essentials.experiments,
        viewMode,
      })

      this.#appLogger.addSessionData(() => ({ routerReturnedData }))
      this.#appLogger.init({
        appLogger: this.#appLogger,
        user: {
          id: get(wixSdk, ['user', 'currentUser', 'id']),
        },
        inSsr: get(wixSdk, ['window', 'rendering', 'env']) === 'backend',
        viewMode,
        platformBiParams: bi,
        browserUrlGetter: () => get(wixSdk, ['location', 'url']),
        reportTrace,
        createRavenClient,
        fedOpsLoggerFactory,
        biLoggerFactory,
      })

      this.#appLogger.traceSync(traceCreators.initAppForPage(), () => {
        const { wixDataProxy, wixDataSchemasProxy } = wixDataProxyCreator(
          this.#appLogger.wixDataCodeZone,
          () => {
            return this.#wixDataCreator({
              baseUrl: wixSdk.location.baseUrl,
              envIsEditor: isEnvEditor(viewMode),
              platformizedWixData: wixSdk.data,
            })
          },
        )

        this.#wixDataProxy = wixDataProxy
        this.#wixDataSchemasProxy = wixDataSchemasProxy
      })

      this.#schemaAPI = schemaAPICreator(
        this.#wixDataSchemasProxy,
        this.#appLogger,
      )

      const dataFetcher = features.fes
        ? new DataFetcher({
            httpClient: essentials.httpClient,
            getRequestParams: () => {
              const {
                query: { instance, gridAppId },
              } = parseUrl(
                `?${this.#getElementorySupport().queryParameters}`,
                true,
              )

              return { instance, gridAppId }
            },
          })
        : new LegacyDataFetcher({
            server: new TempFesClientImpl(
              new WixDataProvider(this.#wixDataProxy, this.#schemaAPI),
            ),
          })

      const dataCache = new DataCache({
        warmupData: wixSdk.window?.warmupData,
      })

      this.#dataBinding = new DataBinding({
        dataFetcher,
        dataCache,
        features,

        appLogger: this.#appLogger,
        errorReporter: this.#errorReporter,
        wixSdk,
        routerReturnedData,
        shouldVerbose: this.#shouldVerbose,
        originalVerboseReporter: this.#originalVerboseReporter,
        automationsClientCreator: this.#automationsClientCreator,
        elementorySupport: global.elementorySupport,
        schemaAPI: this.#schemaAPI,
        wixDataProxy: this.#wixDataProxy,
      })

      return Promise.resolve()
    } catch (e) {
      this.#appLogger.error(e)
      return Promise.reject(e)
    }
  }

  createControllers = rawControllerConfigs => {
    return this.#appLogger.traceSync(traceCreators.createControllers(), () => {
      try {
        if (rawControllerConfigs.length === 0) {
          return []
        }

        return this.#dataBinding.initializeDatasets({ rawControllerConfigs })
      } catch (e) {
        this.#appLogger.error(e)
        return []
      }
    })
  }
}

const createWixDataSchemasClient = (
  elementorySupport,
  siteBaseUrl,
  envIsEditor,
) => {
  const {
    query: { instance, gridAppId },
  } = parseUrl(`?${elementorySupport.queryParameters}`, true)

  const { protocol, hostname } = parseUrl(siteBaseUrl)

  const baseUrl = envIsEditor
    ? undefined
    : `${protocol}//${hostname}/_api/cloud-data/v1/schemas`

  return createDataSchemasClientForBrowser(instance, gridAppId, {
    baseUrl,
  })
}
